import { App, Plugin, UniversalAppState } from "@pimo/pimo-app-builder";
import { GridLayout, TitleCard } from "@pimo/pimo-components";
import { App as ISRMApp } from "isrm-home-portal-types";

import { AppInfoCards } from "../components/app-info-cards";
import { APP_ROUTES } from "../constants";
import { STRAPI_URL } from "../env";
import { fetchApps } from "../helpers/fetch-helpers";

export interface HomePluginState {
  apps: ISRMApp[];
}

export class HomePlugin<AppState extends UniversalAppState & HomePluginState>
  implements Plugin<AppState, HomePluginState>
{
  onRegister(app: App<AppState>): void {
    const view = app.createView({
      name: "Home",
      layout: new GridLayout(),
    });

    const headline = view.addComponent({
      component: TitleCard,
      layoutProps: {
        xs: 12,
      },
    });

    headline.mapState(() => ({
      title: "Information Security Modules",
    }));

    const apps = view.addComponent({
      component: AppInfoCards,
      layoutProps: { xs: 12 },
    });
    apps.mapState(({ apps = [] }) => ({
      apps: apps
        .sort((app1, app2) => app1.name.localeCompare(app2.name))
        .map((app) => ({
          name: app.name,
          description: app.description,
          logo: app.logo?.data?.attributes.url
            ? `${STRAPI_URL}${app.logo?.data.attributes.url}`
            : undefined,
          url: app.url,
          email: app.email,
          emailSubject: `Information Security Modules - ${app.name}`,
          infoText: app.infoText,
          tableEntries: app.tableEntries ?? [],
        })),
    }));

    const route = app.createRoute({
      path: APP_ROUTES.home,
      view,
    });

    route.on("load", async () => {
      const apps = await fetchApps();
      app.setAppState({
        ...app.getAppState(),
        apps,
      });
    });
  }
}
